import { render, staticRenderFns } from "./BatchCreate.vue?vue&type=template&id=200b1c73&scoped=true&"
import script from "./BatchCreate.vue?vue&type=script&lang=js&"
export * from "./BatchCreate.vue?vue&type=script&lang=js&"
import style0 from "./BatchCreate.vue?vue&type=style&index=0&id=200b1c73&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "200b1c73",
  null
  
)

export default component.exports