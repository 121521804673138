<template>
    <div class="BatchCreate" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-select placeholder="商品类型" v-model="types" size="mini" @change="handleChangeTypes">
                <el-option label="标品" :value="0" />
                <el-option label="非标品" :value="1" />
            </el-select>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="handleSave" size="small">保存</el-button>
            <el-button type="primary" @click="handleAdd" size="small">添加</el-button>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px" align="left">
            <el-table
                id="printMe"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="440"
            >
                <el-table-column label="序号" type="index" width="60" align="center" />
                <el-table-column label="名称" width="160">
                    <template slot-scope="scope">
                        <el-input :disabled="scope.row.flag" v-model="scope.row.names" size="mini" />
                    </template>
                </el-table-column>
                <el-table-column label="条码" width="190">
                    <template slot-scope="scope">
                        <span v-for="b in scope.row.bars" :key="b">{{ b }}<br /></span>
                    </template>
                </el-table-column>
                <el-table-column label="规格" width="160">
                    <template slot-scope="scope">
                        <el-input :disabled="scope.row.flag || types == 1" v-model="scope.row.specks" size="mini" />
                    </template>
                </el-table-column>
                <el-table-column label="类目" width="160">
                    <template slot-scope="scope">
                        <el-select
                            :disabled="scope.row.flag"
                            placeholder="请选择类目"
                            v-model="scope.row.categoryCodes"
                            size="mini"
                            filterable
                        >
                            <el-option-group v-for="group in meta.categories" :label="group.name" :key="group.id">
                                <el-option
                                    v-for="category in group.sub"
                                    :label="category.name"
                                    :value="category.code"
                                    :key="category.code"
                                />
                            </el-option-group>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column label="单位(小)" width="160">
                    <template slot-scope="scope">
                        <el-input
                            :disabled="scope.row.flag"
                            v-model="scope.row.units"
                            size="mini"
                            :readonly="types == 1"
                        />
                    </template>
                </el-table-column>
                <el-table-column label="品牌" width="160">
                    <template slot-scope="scope">
                        <el-select
                            :disabled="scope.row.flag"
                            placeholder="请选择品牌"
                            v-model="scope.row.brandCodes"
                            size="mini"
                            filterable
                        >
                            <el-option v-for="bd in meta.brands" :label="bd.name" :value="bd.code" :key="bd.code" />
                        </el-select>
                    </template>
                </el-table-column>

                <el-table-column label="保质期" width="140">
                    <template slot-scope="scope">
                        <el-input :disabled="scope.row.flag" v-model="scope.row.expirationDates" size="mini" />
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button :disabled="scope.row.flag" size="mini" @click="handleSetBars(scope)"
                            >设置条码</el-button
                        >
                        <el-button size="mini" type="danger" @click="handleDelete(scope.$index)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>

        <el-dialog title="条码" :visible.sync="current.settingBars" :close-on-click-modal="false">
            <el-form ref="setBar" :rules="rules" :model="current">
                <el-form-item prop="tempBar">
                    <el-input
                        type="text"
                        v-model="current.tempBar"
                        size="mini"
                        style="width: 50%; float: left"
                        placeholder="请输入条码"
                    />
                    <el-button
                        v-if="MakeAvailable"
                        @click="MakeAvailables"
                        size="mini"
                        style="float: left; margin-top: 5px; margin-left: 20px"
                        type="primary"
                        >设为可用</el-button
                    >
                </el-form-item>
                <el-form-item>
                    <br />&nbsp;<el-button @click="handleAddTempBar" size="mini">添加</el-button>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="current.bars"
                        multiple
                        filterable
                        allow-creates
                        default-first-option
                        placeholder="请输入新的条码"
                    >
                        <el-option v-for="item in current.bars" :key="item" :label="item" :value="item" />
                    </el-select>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button size="mini" type="primary" @click="handleSetBarsOk">确 定</el-button>
                <el-button size="mini" @click="current.settingBars = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import UrlUtils from '../../../../js/UrlUtils';

export default {
    name: 'BatchCreate',
    data() {
        const _this = this;
        return {
            rules: {
                tempBar: [
                    {
                        validator: (rule, value, callback) => {
                            if (!value || !/^\d{13}$|^\d{8}$|^\d{12}$/.test(value.trim())) {
                                return callback(new Error('请输入13位或12位或8位数字'));
                            }

                            if (_this.current.bars.find((a) => a == value)) {
                                return callback(new Error('该验证码已存在'));
                            }
                            if (_this.tableData.find((a) => a.bars.find((b) => b == value))) {
                                return callback(new Error('该验证码已被其他商品使用'));
                            }

                            _this.$http
                                .get('/goods/sku/bar/checkRepeat?bar=' + value)
                                .then((rst) => {
                                    if (rst.data.data.sku) {
                                        _this.sku.names = rst.data.data.sku.name;
                                        _this.sku.bars = rst.data.data.bars;
                                        _this.sku.specks = rst.data.data.sku.specs;
                                        _this.sku.units = rst.data.data.sku.unit;
                                        _this.sku.categoryCodes = rst.data.data.sku.categoryCode;
                                        _this.sku.brandCodes = rst.data.data.sku.brandCode;
                                        _this.sku.expirationDates = rst.data.data.sku.expirationDate;
                                        _this.sku.skuCodes = rst.data.data.sku.code;
                                        _this.sku.flag = true;
                                    }
                                    if (rst.data.data.flag == 1) {
                                        _this.MakeAvailable = false;
                                        return callback(new Error('该条码商品您已可用，请勿重复添加'));
                                    }
                                    if (rst.data.data.flag == 2) {
                                        _this.skuName = rst.data.data.sku.name;
                                        _this.MakeAvailable = true;
                                        return callback(new Error('该条码已对应商品，如需添加该商品，请点击设为可用'));
                                    }
                                    if (rst.data.data.flag == 3) {
                                        _this.MakeAvailable = false;
                                        return callback();
                                    }
                                })
                                .catch((rst) => {
                                    return callback(new Error('服务器忙,请稍后重试'));
                                });
                            // _this.$http
                            //     .get('/goods/sku/bar/list?bar=' + value)
                            //     .then((rst) => {
                            //         if (rst.data.status == 200) {
                            //             if (rst.data.data.length) {
                            //                 for (let i = 0; i < rst.data.data.length; i++) {
                            //                     if (rst.data.data[i].bar == value) {
                            //                         return callback(new Error('该验证码已被其他商品使用'));
                            //                     }
                            //                 }
                            //             }
                            //             return callback();
                            //         } else {
                            //             return callback(new Error('服务器忙,请稍后重试'));
                            //         }
                            //     })
                            //     .catch((rst) => {
                            //         return callback(new Error('服务器忙,请稍后重试'));
                            //     });
                        },
                        trigger: 'blur',
                    },
                ],
            },
            types: 0,
            tableData: [],
            sku: {},
            url: {
                page: '/goods/sku/createExtend',
            },
            loading: false,
            meta: {
                brands: [],
                categories: [],
            },
            current: {
                tempBar: '',
                settingBars: false,
                bars: [],
                rowIndex: -1,
            },
            MakeAvailable: false,
            skuName: '',
            dataIndex: 0,
        };
    },
    mounted() {
        const _this = this;
        UrlUtils.GoodsBrands(_this, (rst) => {
            _this.meta.brands = rst;
        });
        UrlUtils.GroupedGoodsCategories(_this, (rst) => {
            _this.meta.categories = rst;
        });
        // console.log()
    },
    methods: {
        //设为可用
        MakeAvailables() {
            this.$confirm(`条码'${this.current.tempBar}'对应商品'${this.skuName}'将在您的机构组中可用`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    if (this.tableData.indexOf(this.sku) === -1) {
                        this.handleAdd();
                        this.tableData[this.dataIndex] = this.sku;
                        this.tableData.pop();
                    } else {
                        this.$message.error('请勿重复添加');
                    }
                    this.current.settingBars = false;
                    // _this.$http.post(`/goods/sku/deptGroup/save/${this.current.tempBar}`).then((rst) => {
                    //     if (rst.data.status == 200) {
                    //         this.current.tempBar = '';
                    //         this.MakeAvailable = false;
                    //         Message.success({ message: '操作成功' });
                    //     } else {
                    //         Message.error({ message: '操作失败' + rst.data.status });
                    //     }
                    // });
                })
                .catch(() => {});
        },
        handleChangeTypes() {
            const _this = this;
            if (!this.types) {
                return;
            }
            const _a = JSON.parse(JSON.stringify(this.tableData));
            _a.forEach((v, i, a) => {
                a[i].units = 'KG';
            });
            _this.tableData = _a;
        },
        handleSave() {
            const _this = this;
            //校验非空
            if (!_this.tableData.length) {
                return;
            }
            _this.tableData.forEach((v, i, a) => {
                _this.tableData[i].types = _this.types;
                if (_this.types) {
                    a[i].specks = '';
                }
            });

            //校验行数据
            for (let i = 0; i < _this.tableData.length; i++) {
                const _d = _this.tableData[i];
                if (!window.ef.valid.skuName(_d.names, true)) {
                    this.$message.error('请正确输入名称');
                    return;
                }
                if (!_d.bars.length) {
                    this.$message.error('至少设置一个条码');
                    return;
                }
                if (this.types == 1) {
                    if (window.ef.valid.specs(_d.specks, true)) {
                        this.$message.error('请正确输入规格');
                        return;
                    }
                }
                if (!_d.categoryCodes) {
                    this.$message.error('请选择商品类目');
                    return;
                }
                if (!window.ef.valid.unit(_d.units, true)) {
                    this.$message.error('请正确输入单位');
                    return;
                }
                if (!_d.brandCodes) {
                    this.$message.error('请选择商品品牌');
                    return;
                }
                if (!window.ef.valid.expiration(_d.expirationDates, true)) {
                    this.$message.error('请输入保质期');
                    return;
                }
            }

            const _p = {
                names: _this.tableData.map((v) => v.names || ''),
                bars: _this.tableData.map((v) => v.bars.join('/') || ''),
                specks: _this.tableData.map((v) => (_this.types ? '  ' : v.specks || '')),
                types: _this.tableData.map((v) => _this.types),
                categoryCodes: _this.tableData.map((v) => v.categoryCodes || ''),
                brandCodes: _this.tableData.map((v) => v.brandCodes || ''),
                units: _this.tableData.map((v) => v.units || ''),
                expirationDates: _this.tableData.map((v) => v.expirationDates || ''),
                skuCodes: _this.tableData.map((v) => v.skuCodes || ''),
            };

            UrlUtils.PostRemote(this, '/goods/sku/createExtend/', _p);
        },
        handleAdd() {
            const _this = this;
            this.tableData = this.tableData.concat({
                names: '',
                bars: [],
                specks: '',
                types: 0,
                categoryCodes: '',
                brandCodes: '',
                units: _this.types ? 'KG' : '',
                expirationDates: '',
            });
        },
        handleSetBars(scope) {
            this.dataIndex = scope.$index;
            this.current.settingBars = true;
            this.current.bars = scope.row.bars || [];
            this.current.rowIndex = scope.$index;
        },
        handleDelete(index) {
            const _a = this.tableData;
            _a.splice(index, 1);
            this.tableData = _a;
        },
        handleSetBarsOk() {
            const _this = this;
            const _a = this.tableData;
            _a[this.current.rowIndex].bars = JSON.parse(JSON.stringify(_this.current.bars));
            this.tableData = _a;
            this.current.settingBars = false;
        },
        handleAddTempBar() {
            const _this = this;
            this.$refs.setBar.validateField('tempBar', (validMessage) => {
                if (validMessage != '') {
                    return false;
                }
                _this.current.bars = _this.current.bars.concat([_this.current.tempBar]);
                _this.current.tempBar = '';
                _this.$refs.setBar.clearValidate('tempBar');
            });
        },
    },
};
</script>

<style scoped>
.BatchCreate .el-form-item {
    margin-bottom: 0;
}

.BatchCreate .el-table th.gutter {
    display: table-cell;
}
</style>
